<template>
    <BaseDropdown position="left">
        <template #trigger>
            <ModuleTag
                v-if="searchModule"
                :icon="$getModuleIcon(searchModule.name)"
                :label="$t(searchModule.name + '.table.title')"
                :color="searchModule?.color ?? 'primary'">
                <template
                    v-if="searchables?.length"
                    #append>
                    <Icon
                        name="heroicons:chevron-down-16-solid"
                        class="w-4 h-4" />
                </template>
            </ModuleTag>
            <ModuleTag
                v-else
                icon="heroicons:bars-3-bottom-left"
                :label=" $t('globalSearch.everything')"
                color="gray">
                <template
                    v-if="searchables?.length"
                    #append>
                    <Icon
                        name="heroicons:chevron-down-16-solid"
                        class="w-4 h-4" />
                </template>
            </ModuleTag>
        </template>
        <BaseMenuItem @click="globalSearchStore.setCurrentModule(null)">
            <ModuleTag
                reduced
                icon="heroicons:bars-3-bottom-left"
                :label=" $t('globalSearch.everything')"
                color="gray" />
            <span :class="{'font-bold': !searchModule?.name}">
                {{ $t('globalSearch.everything') }}
            </span>
        </BaseMenuItem>
        <BaseMenuItem
            v-for="searchable in searchables"
            :key="searchable"
            @click="globalSearchStore.setCurrentModule(searchable)">
            <ModuleTag
                reduced
                :icon="$getModuleIcon(searchable)"
                :label="$t(searchable + '.table.title')"
                :color="appModules[searchable]?.color ?? 'primary'" />
            <span :class="{'font-bold': searchModule?.name === searchable}">
                {{ $t(searchable + '.table.title') }}
            </span>
        </BaseMenuItem>
    </BaseDropdown>
</template>

<script setup lang="ts">
import {$getModuleIcon, appModules} from "@/utils/appModules";
import {useGlobalSearchStore} from '@/store/globalSearch'

const globalSearchStore = useGlobalSearchStore()

const searchModule = computed(() => {
    return appModules[globalSearchStore.currentModule] ?? null
})

const searchables = computed(() => Object.keys(appModules).filter(module => appModules[module].search))

</script>

<style scoped>

</style>