<template>
    <Popover
        v-slot="slotProps">
        <div
            class="leading-3">
            <PopoverButton
                ref="searchPopoverTrigger"
                as="div"
                class="inline-flex items-center gap-x-1 text-sm cursor-pointer">
                <div class="hidden bg-rose-500" />
            </PopoverButton>

            <Transition
                enter-active-class="transition ease-out duration-200"
                enter-from-class="opacity-0 translate-y-1"
                enter-to-class="opacity-100 translate-y-0"
                leave-active-class="transition ease-in duration-150"
                leave-from-class="opacity-100 translate-y-0"
                leave-to-class="opacity-0 translate-y-1">
                <PopoverPanel
                    class="absolute -left-8 -right-8 sm:left-0 sm:right-0 top-8 z-10 flex transition-opacity"
                    :style="offset">
                    <div
                        ref="panel"
                        class="w-full flex-auto rounded-xl bg-white text-sm overflow-hidden bg-white dark:bg-gray-800 text-sm leading-6 shadow-lg ring-1 dark:ring-gray-300 ring-gray-900/5">
                        <div
                            v-if="!globalSearchStore.searchInput"
                            class="p-3 text-neutral-300 ">
                            <i>Geben Sie einen Suchbegriff ein</i>
                        </div>
                        <div
                            v-else
                            class="max-h-[80vh] overflow-auto">
                            <Searchable
                                v-for="searchable in searchables"
                                :key="'searchable-' + searchable"
                                :searchable="searchable"
                                @close="slotProps.close()" />
                        </div>
                    </div>
                </PopoverPanel>
            </Transition>
        </div>
    </Popover>
</template>

<script setup>
import {useGlobalSearchStore} from '@/store/globalSearch'
import {appModules} from '@/utils/appModules'
import Searchable from '@/components/layouts/GlobalSearch/Searchable'

const globalSearchStore = useGlobalSearchStore()
const searchables = computed(() => Object.keys(appModules).filter(
    module => appModules[module].search && (!globalSearchStore.currentModule || globalSearchStore.currentModule === module)
))

// TODO maybe use composable
const searchPopoverTrigger = ref(null);
const panel = ref(null);
const {width: windowWidth} = useWindowSize()
const panelBoundingRect = useElementBounding(panel)

const offset = computed(() => {
    if (!panelBoundingRect) return ''

    const PADDING = 15
    // popover default positions
    const posX = panelBoundingRect.x.value
    const rightX = posX + panelBoundingRect.width.value

    const leftOffset = (posX < 0) ? Math.floor(posX) - PADDING : 0
    const rightOffset = (rightX > windowWidth.value) ? Math.floor(rightX - windowWidth.value) + PADDING : 0

    //position by props, tailwindcss cannot handle dynamic class bindings at runtime, so this has to be done manually
    return `transform: translateX(calc(${leftOffset}px - ${rightOffset}px));`
})

defineExpose({
    searchPopoverTrigger
})
</script>

<style scoped>

</style>