import {$getModuleColor, $getModuleIcon} from "./appModules";

export const sidebarMenu: SidebarMenu = [
    {
        title: 'default',
        menu: [
            {
                title: 'menu.dashboard',
                path: '/service',
                icon: $getModuleIcon('dashboard'),
                color: $getModuleColor('dashboard'),
            },
            {
                title: 'menu.processes',
                path: '/service/processes',
                icon: $getModuleIcon('processes'),
                color: $getModuleColor('processes'),
                ability: ['viewAny', 'processes']
            },
            {
                title: 'menu.payment',
                path: '/service/payments/paymentRuns',
                icon: $getModuleIcon('payments'),
                color: $getModuleColor('payments'),
                ability: ['viewAny', 'payment']
            },
            {
                title: 'menu.statistics',
                path: '/service/statistics',
                icon: $getModuleIcon('statistics'),
                color: $getModuleColor('statistics'),
                ability: ['viewAny', 'statistics']
            },
            {
                title: 'menu.jugendzaehlt',
                path: '/service/jugendzaehlt',
                icon: $getModuleIcon('jugendzaehlt'),
                color: $getModuleColor('jugendzaehlt'),
                children: [
                    {
                        title: 'menu.regions',
                        path: '/service/jugendzaehlt/regions'
                    },
                    {
                        title: 'menu.parishes',
                        path: '/service/jugendzaehlt/parishes'
                    },
                ]
            },
            {
                title: 'menu.settings',
                path: '/service/settings',
                icon: $getModuleIcon('settings'),
                color: $getModuleColor('settings'),
                children: [
                    {
                        title: 'menu.users',
                        path: '/service/settings/users',
                        ability: ['viewAny', 'users']
                    },
                    {
                        title: 'menu.organisations',
                        path: '/service/settings/organisations',
                        ability: ['viewAny', 'organisations']
                    },
                    {
                        title: 'menu.loginProviders',
                        path: '/service/settings/loginProviders',
                        ability: ['viewAny', 'loginProviders']
                    },
                ]
            },
            {
                title: 'menu.basicData',
                path: '/service/basicData',
                icon: $getModuleIcon('basicData'),
                color: $getModuleColor('basicData'),
                ability: ['viewAny', 'basicData'],
                children: [
                    {
                        title: 'menu.fundingPrograms',
                        path: '/service/basicData/fundingPrograms',
                        ability: ['viewAny', 'fundingPrograms']
                    },
                    {
                        title: 'menu.forms',
                        path: '/service/basicData/forms',
                        ability: ['viewAny', 'forms']
                    },
                    {
                        title: 'menu.statistics',
                        path: '/service/basicData/statistics',
                        ability: ['viewAny', 'statistics']
                    },
                    {
                        title: 'menu.categories',
                        path: '/service/basicData/categories',
                        ability: ['viewAny', 'categories']
                    },
                    {
                        title: 'menu.statuses',
                        path: '/service/basicData/statuses',
                        ability: ['viewAny', 'statuses']
                    },
                    {
                        title: 'menu.roles',
                        path: '/service/basicData/roles',
                        ability: ['viewAny', 'roles']
                    },
                    {
                        title: 'menu.years',
                        path: '/service/basicData/years',
                        ability: ['viewAny', 'years']
                    },
                    {
                        title: 'menu.mailTemplates',
                        path: '/service/basicData/mailTemplates',
                        ability: ['viewAny', 'mailTemplates']
                    },
                    {
                        title: 'menu.ags',
                        path: '/service/basicData/ags',
                        ability: ['viewAny', 'ags']
                    },
                ]
            },
        ]
    },
];