<template>
    <div>
        <div class="bg-gray-50 dark:bg-gray-700 p-3">
            <NuxtLink
                :to="useLocalePath()({name: searchableSettings.listing, query: {search: searchInput}})"
                @click="prepareListingView">
                <div class="flex items-center justify-between gap-x-3">
                    <h3 class="text-sm font-semibold leading-6 text-gray-900">
                        {{ $t(searchable + '.table.title') }}
                    </h3>
                    <div v-if="(searchResults && searchResults.length)">
                        {{ $t('globalSearch.showAll', {count: total || ''}) }}
                    </div>
                </div>
            </NuxtLink>
        </div>
        <div v-if="!searchInput" />
        <div
            v-else-if="loading"
            class="p-3 animate-pulse w-full">
            <div class="h-4 w-full dark:bg-gray-600 bg-gray-200 rounded-full" />
        </div>
        <div
            v-else-if="!(searchResults && searchResults.length)"
            class="p-3">
            {{ $t('globalSearch.noResults') }}
        </div>
        <ul
            v-else
            class="p-3">
            <NuxtLink
                v-for="result in searchResults"
                :key="searchable + '-result-' + result[searchableSettings.idProp || 'id']"
                :to="useLocalePath()({name: searchableSettings.detail, params: {
                    [searchableSettings.idProp || 'id'] : result[searchableSettings.idProp || 'id']
                }})"
                @click="emits('close')">
                <li class="flex -m-3 p-3 justify-between hover:bg-gray-100 dark:hover:bg-gray-600">
                    <span>
                        {{ result[searchableSettings.nameProp || 'name'] }}
                    </span>
                    <strong>
                        {{ result[searchableSettings.idProp || 'id'] }}
                    </strong>
                </li>
            </NuxtLink>
        </ul>
    </div>
</template>

<script setup>
import {appModules} from '@/utils/appModules'
import {useGlobalSearchStore} from '@/store/globalSearch'
import {throttle} from 'lodash'

const props = defineProps({
    searchable: {
        type: String,
        required: true
    }
})

const emits = defineEmits(['close'])

const globalSearchStore = useGlobalSearchStore()

const loading = ref(false)
const searchResults = ref(null)
const total = ref(null)

const searchInput = computed(() => {
    return globalSearchStore.searchInput ?? null
})
const tableModule = computed(() => {
    return tableModules[props.searchable] ?? null
})

const searchableSettings = computed(() => {
    return {
        // computed default values
        idProp: 'id',
        nameProp: 'name',
        path: (tableModule?.value?.route || 'spa.' + props.searchable) + '.index',
        listing: props.searchable,
        detail: props.searchable + '-id',
        // user defined values
        ...appModules[props.searchable]?.search || {}
    }
})

const {$apiRoute} = useNuxtApp()
const startSearch = throttle(() => {
    loading.value = true
    searchResults.value = null
    total.value = null
    if (searchableSettings.value.path) {
        $lara.get($apiRoute(searchableSettings.value.path), {
            params: {
                search: globalSearchStore.searchInput,
                perPage: 5,
                page: 1
            }
        }).then(response => {
            searchResults.value = response.data
            total.value = response.total
        }).finally(() => {
            loading.value = false
        })
    }
}, 500)

watch(() => globalSearchStore.searchInput, value => {
    if (!value) {
        loading.value = false
        searchResults.value = null
        total.value = null
    } else {
        startSearch()
    }
}, {immediate: true})

function prepareListingView() {
    globalSearchStore.setCurrentModule(props.searchable)
    emits('close')
}
</script>

<style scoped>

</style>