<template>
    <div
        class="flex gap-2 items-center rounded text-white px-2 py-1 font-bold"
        :class="backgroundClass">
        <div
            class="flex items-center"
            :class="{'sm:hidden': !complete && !reduced}">
            <slot name="icon">
                <Icon
                    :name="icon"
                    class="w-5 h-5" />
            </slot>
        </div>
        <small :class="{'hidden': !complete || reduced, 'sm:inline': !complete && !reduced}">
            <slot>{{ label }}</slot>
        </small>
        <slot name="append" />
    </div>
</template>

<script setup lang="ts">
const props = defineProps({
    label: {
        type: String,
        required: true
    },
    icon: {
        type: String,
        required: true
    },
    color: {
        type: String,
        default: 'primary'
    },
    complete: {
        type: Boolean,
        default: false
    },
    reduced: {
        type: Boolean,
        default: false
    }
})

const backgroundClass = computed(() => {
    return backgroundClasses[props.color] ?? backgroundClasses['primary']
})

const backgroundClasses = {
    'primary': 'bg-primary-500',
    'secondary': 'bg-secondary-500',
    'success': 'bg-success-500',
    'danger': 'bg-danger-500',
    'warning': 'bg-warning-500',
    'info': 'bg-info-500',
    'teal': 'bg-teal-500',
    'indigo': 'bg-indigo-500',
    'gray': 'dark:bg-gray-700 bg-gray-300',
}
</script>

<style scoped>

</style>