<template>
    <div>
        <Sidebar />
        <ImpersonateBanner />
        <BaseSlideOver
            :header-color="'primary'"
            :title="(slideOverStore?.title) ? $t(slideOverStore.title, slideOverStore.translationParams) : ''"
            :description="(slideOverStore?.description) ? $t(slideOverStore.description) : ''"
            :model-value="slideOverStore.isSlideOverOpen"
            :size="slideOverStore.size"
            @update:modelValue="slideOverStore.setSlideOverOpen($event)">
            <Component
                :is="components[slideOverStore.component]"
                @close="slideOverStore.isSlideOverOpen = false" />
        </BaseSlideOver>
        <div class="lg:pl-72 h-screen overflow-auto bg-neutral-100 dark:bg-gray-800">
            <Header />
            <main>
                <div>
                    <slot />
                </div>
            </main>
        </div>
        <RequestLog />
    </div>
</template>

<script setup>
import FundingProgramsSelection from "@/components/global/FundingPrograms/FundingProgramsSelection";
import CategoryEdit from "@/components/pages/categories/CategoryEdit";
import Header from "@/components/layouts/Header";
import Sidebar from "@/components/layouts/Sidebar";
import ImpersonateBanner from "@/components/layouts/ImpersonateBanner";
import {useWebsocketStore} from "@/store/websockets";
import {useStatic} from "@/store/static";
import {useSlideOverStore} from "@/store/slideOver";

const slideOverStore = useSlideOverStore()
const staticStore = useStatic()

const components = {
    FundingProgramsSelection,
    CategoryEdit,
}

onMounted(() => {
    useWebsocketStore().init()
    staticStore.getStaticData()
})

useHead({
    bodyAttrs: {
        class: 'h-full bg-gray-50'
    },
    htmlAttrs: {
        class: 'h-full'
    }
})
</script>
