<template>
    <VForm
        v-slot="validation"
        @submit="onSubmit">
        <div class="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-2">
            <CategoryFormGeneral
                v-model="categoryModel"
                :loading="tableStores.categories().loading" />
        </div>

        <BaseSubmitButtons
            :submit-label="$t('categories.edit.save')"
            :loading="loading"
            :validation="validation"
            @cancel="emits('close')" />
    </VForm>
</template>

<script setup>
import {$lara} from "@/utils/$larafetch";
import {useNotification} from "@/composables/useNotification";
import CategoryFormGeneral from "@/components/pages/categories/CategoryFormGeneral";
import {useSlideOverStore} from "@/store/slideOver";
import {tableStores} from '@/store/tableModules'
import {useEventBus} from "@vueuse/core";


const {$apiRoute} = useNuxtApp()
const {t: $t} = useI18n()
const emits = defineEmits(['close'])

const slideOverStore = useSlideOverStore()
const categoryModel = ref({})
const loading = ref(false)

onMounted(() => {
    categoryModel.value = {...slideOverStore.context};
})

function onSubmit(values, actions) {
    tableStores.categories().loading = true

    const notification = useNotification()

    $lara.put($apiRoute('spa.basedata.categories.update', {category: categoryModel.value.id}), {
        body: {...categoryModel.value}
    }).then((response) => {
        tableStores.categories().loading = false
        notification.success($t('categories.edit.saveCategorySuccess'))
        tableStores.categories().listing.output = 'collectionAll'
        tableStores.categories().fetchData(true)
        // open path to category in the tree structure
        useEventBus('newCategory').emit(response.data)
        emits('close')
    }).catch((error) => {
        if(typeof actions.setErrors === "function" && error?.data?.errors) {
            actions?.setErrors(error.data.errors)
        }
        notification.error($t('categories.edit.saveCategoryError'), error?.data?.message)
        tableStores.categories().loading = false
    })
}
</script>

